
















































import Vue from "vue";
import { Component } from "vue-property-decorator";

import { axiosInstance as axios } from "@/store/";

@Component
export default class FakePartnerSub extends Vue {
  menu = false;

  uuid = "";
  productID = process.env.VUE_APP_POPUP_FAKE_PRODUCTID || "";
  access = "full";
  date = "";
  apiKey = process.env.VUE_APP_POPUP_FAKE_APIKEY || "";
  type = "";

  get title(): string {
    return this.$route.meta.title;
  }

  async sub(): Promise<void> {
    if (!this.uuid || !this.productID || !this.access || !this.date) return;

    try {
      await axios.post(
        process.env.VUE_APP_FAKE_SUBSCRIPTION_PATH,
        {
          UUID: this.uuid,
          ProductID: this.productID,
          Access: this.access,
          EndOfSubscriptionDate: new Date(this.date).toISOString(),
          Type: this.type,
          OneTimeUse: false,
        },
        {
          headers: {
            "x-cg-api-key": process.env.VUE_APP_POPUP_FAKE_APIKEY,
          },
        }
      );
      /*
      window.opener.postMessage(
        {
          success: true,
        },
        location.origin
      );
      */
      const res = await axios.post(
        process.env.VUE_APP_FAKE_AUTH_PATH,
        {
          UUID: this.uuid,
          OneTimeUse: false,
        },
        {
          headers: {
            "x-cg-api-key": process.env.VUE_APP_POPUP_FAKE_APIKEY,
          },
        }
      );

      let url = new URL(location.origin);
      // get referrer
      if (document.referrer) url = new URL(document.referrer);

      const token = res.data.token;
      url.searchParams.set("cgtoken", token);

      location.replace(url.href);
    } catch (err) {
      console.error(err);
      /* window.opener.postMessage(
        {
          error: "some error",
        },
        location.origin
      ); */
    }
  }

  cancel(): void {
    window.opener.postMessage(
      {
        success: false,
      },
      location.origin
    );
  }

  /**
   * Check whether to generate a random UUID.
   * Internally, this is determined by the presence of the `ruuid` query parameter.
   * @returns {boolean} Whether to generate a random UUID.
   */
  get generateRandomUUID(): boolean {
    return !!this.$route.query.ruuid;
  }

  created(): void {
    // set the date to 15 days from now
    const date = new Date();
    date.setDate(date.getDate() + 15);
    this.date = date.toISOString().split("T")[0];

    if (this.generateRandomUUID) {
      // generate a random UUID starting with "FakeTest-" and 16 random numbers
      this.uuid = `FakeTest-${Math.floor(1000000000000000 + Math.random() * 9000000000000000)}`;
    }

    this.type = this.$route.meta.subscription.type;
  }
}
